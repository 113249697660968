import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/josh/Code/hub/joshayoung_website/src/components/layout-markdown.js";
import ResourcesLinksOutput from "../output/resources-links-output";
import ResourcesOutput from "../output/resources-output";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Resources`}</h1>
    <p>{`Here, I will be listing helpful resources, documentation, and tools that I use in my daily web development
workflow. I will continually update this page as I discover more tools that are beneficial. I have included a list grouped into categories below.`}</p>
    <ResourcesLinksOutput mdxType="ResourcesLinksOutput" />
    <ResourcesOutput mdxType="ResourcesOutput" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      